/* Copyright Levelise Ltd 2020-2024 */
import React, { useEffect, useState, useContext } from 'react';
import config from '../../config';
import UserService from '../../services/user-service';
import FleetContext from '../../contexts/FleetContext';
import { Button } from '../Form/index';
import { PERMISSIONS, hide, show, distinct, combined } from '../../utils/constants';
import { hasPermission } from '../../utils/utils';
import FleetService from '../../services/fleet-service';
import TabContainer from '../TabContainer';
import SwitchComponent from '../reusableInputComponents/SwitchComponent';

const SettingsFrom = ({ handleRedirect }) => {
	const facilityTimezone = 1;
	const fleetTimezone = 2;
	const localTimezone = 3;
	const utcTimezone = 4;
	const fleetContext = useContext(FleetContext);
	const [status, setStatus] = useState(show);
	const [performance, setPerformance] = useState(hide);
	const [battery, setBattery] = useState(hide);
	const [energy, setEnergy] = useState(show);
	const [logs, setLog] = useState(hide);
	const [facilityInfo, setFacilityInfo] = useState(show);
	const [facilityData, setFacilityData] = useState(combined);
	const [aggFacilityData, setAggFacilityData] = useState(combined);

	const [fleetPageTimezone, setFleetPageTimezone] = useState();
	const [facilityPageTimezone, setFacilityPageTimezone] = useState();

	const [disabledSettingButton, setDisabledSettingButton] = useState(true);

	const [credits, setCredits] = useState(hide);
	const [forecasts, setForecasts] = useState(show);
	const [initialPage, setInitialPage] = useState(1);

	const handleSavePreferences = (e) => {
		e.preventDefault();
		const preference_values = {
			[config.status]: status,
			[config.performance]: performance,
			[config.battery]: battery,
			[config.energyData]: energy,
			[config.logsPanel]: logs,
			[config.facilityInfo]: facilityInfo,
			[config.facilityData]: facilityData,
			[config.aggFacilityData]: aggFacilityData,
			[config.fleetPageTimezone]: fleetPageTimezone,
			[config.facilityPageTimezone]: facilityPageTimezone,
			[config.showCreditsManagement]: credits,
			[config.showForecasts]: forecasts,
			[config.initialPage]: initialPage,
		};
		const preferences = {
			[config.status]: status === show,
			[config.performance]: performance === show,
			[config.battery]: battery === show,
			[config.energyData]: energy === show,
			[config.logsPanel]: logs === show,
			[config.facilityInfo]: facilityInfo === show,
			[config.facilityData]: facilityData,
			[config.aggFacilityData]: aggFacilityData,
			[config.fleetPageTimezone]: fleetPageTimezone,
			[config.facilityPageTimezone]: facilityPageTimezone,
			[config.showCreditsManagement]: credits === show,
			[config.showForecasts]: forecasts === show,
			[config.initialPage]: initialPage,
		};

		UserService.setPreferences(preference_values);

		handleSetPreferences(preferences);
		fleetContext.setDisplay('');
		handleRedirect();
	};

	const handleSetPreferences = (preferences) => {
		FleetService.setUserPreferences(UserService.getUsername(), preferences)
			.then((res) => res)
			.catch(console.error);
	};

	const handleCancelPreferences = (e) => {
		e.preventDefault();
		handleRedirect();
	};

	const getInitialPageState = (page) => {
		let pageNumber = 1;
		if (hasPermission(PERMISSIONS.CAN_ACCESS_ORDERS) ||  (hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER) && page !== 3)) {
			pageNumber = page;
		} else if (hasPermission(PERMISSIONS.CAN_ACCESS_DR_BATTERY)) {
			if (page === 1 || page === 4) {
				pageNumber = page;
			}
		}

		if (pageNumber < 1 || pageNumber > 4) {
			pageNumber = 1;
		}

		setInitialPage(pageNumber);
	};

	const getInitialPageSettings = () => {
		const values = [{ label: 'User', value: 4 }];
		if (hasPermission(PERMISSIONS.CAN_ACCESS_DR_BATTERY)) {
			values.push({ label: 'Fleet', value: 1 });
		}

		if (hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER)) {
			values.push({ label: 'Fleet customers', value: 2 });
		}

		if (hasPermission(PERMISSIONS.CAN_ACCESS_ORDERS)) {
			values.push({ label: 'Orders', value: 3 });
		}

		values.sort((a, b) => a.value - b.value);

		return values;
	};

	const renderFleetPreferences = () => {
		if (hasPermission(PERMISSIONS.CAN_ACCESS_DR_BATTERY)) {
			return (
				<div className="fleet-preferences">
					<div className="fleet-preferences-title">Fleet page</div>
					{hasPermission(PERMISSIONS.CAN_ACCESS_AF) && (
						<div className="preference-wrapper">
							<div className="form-left">Graphs </div>
							<SwitchComponent
								onChange={(value) => setAggFacilityData(value)}
								controlState={aggFacilityData}
								values={[
									{ value: combined, label: 'Combined' },
									{ value: distinct, label: 'Distinct' },
								]}
								height={23.5}
								width={270}
								fontSize="0.7rem"
							/>
						</div>
					)}
					<div className="preference-wrapper">
						<div className="form-left">Status </div>
						<SwitchComponent
							onChange={(value) => setStatus(value)}
							controlState={status}
							values={[
								{ value: show, label: 'Show' },
								{ value: hide, label: 'Hide' },
							]}
							height={23.5}
							width={270}
							marginTop={4}
							fontSize="0.7rem"
						/>
					</div>
					{hasPermission(PERMISSIONS.CAN_ACCESS_AF) && (
						<div className="preference-wrapper">
							<div className="form-left">Performance </div>
							<SwitchComponent
								name="performance"
								onChange={(value) => setPerformance(value)}
								controlState={performance}
								values={[
									{ value: show, label: 'Show' },
									{ value: hide, label: 'Hide' },
								]}
								height={23.5}
								width={270}
								marginTop={4}
								fontSize="0.7rem"
							/>
						</div>
					)}
					<div className="preference-wrapper">
						<div className="form-left">Default timezone</div>
						<SwitchComponent
							onChange={(value) => setFleetPageTimezone(parseInt(value))}
							controlState={fleetPageTimezone}
							values={[
								{ value: facilityTimezone, label: 'Facility' },
								{ value: fleetTimezone, label: 'Fleet' },
								{ value: localTimezone, label: 'Local' },
								{ value: utcTimezone, label: 'UTC' },
							]}
							height={23.5}
							width={270}
							marginTop={4}
							fontSize="0.7rem"
						/>
					</div>
				</div>
			);
		}
	};

	const renderFleetCustomerPreferences = () => {
		if (hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER)) {
			return (
				<div className="fleet-preferences">
					<div className="fleet-preferences-title">Fleet customers page</div>
					<div className="preference-wrapper">
						<div className="form-left">Credits </div>
						<SwitchComponent
							onChange={(value) => setCredits(value)}
							controlState={credits}
							values={[
								{ value: show, label: 'Show' },
								{ value: hide, label: 'Hide' },
							]}
							height={23.5}
							width={270}
							marginTop={4}
							fontSize="0.7rem"
						/>
					</div>
				</div>
			);
		}
	};

	const initialPagePreference = () => {
		if (hasPermission(PERMISSIONS.CAN_ACCESS_DR_BATTERY)) {
			return (
				<div className="fleet-preferences">
					<div className="fleet-preferences-title">General settings</div>
					<div className="preference-wrapper">
						<div className="form-left">Initial page </div>
						<SwitchComponent
							onChange={(value) => setInitialPage(value)}
							controlState={initialPage}
							values={getInitialPageSettings()}
							height={23.5}
							width={270}
							marginTop={4}
							fontSize="0.7rem"
						/>
					</div>
				</div>
			);
		}
	};

	const renderFacilityPreferences = () => {
		return (
			<div className="facility-preferences">
				<div className="facility-preferences-title">Facility page</div>
				<div className="preference-wrapper">
					<div className="form-left">Graphs </div>
					<SwitchComponent
						onChange={(value) => setFacilityData(value)}
						controlState={facilityData}
						values={[
							{ value: combined, label: 'Combined' },
							{ value: distinct, label: 'Distinct' },
						]}
						height={23.5}
						width={270}
						fontSize="0.7rem"
					/>
				</div>
				<div className="preference-wrapper">
					<div className="form-left">Forecasts </div>
					<SwitchComponent
						onChange={(value) => setForecasts(value)}
						controlState={forecasts}
						values={[
							{ value: show, label: 'Show' },
							{ value: hide, label: 'Hide' },
						]}
						height={23.5}
						width={270}
						fontSize="0.7rem"
						marginTop={4}
					/>
				</div>
				<div className="preference-wrapper">
					<div className="form-left">Energy panel </div>
					<SwitchComponent
						onChange={(value) => setEnergy(value)}
						controlState={energy}
						values={[
							{ value: show, label: 'Show' },
							{ value: hide, label: 'Hide' },
						]}
						height={23.5}
						width={270}
						marginTop={4}
						fontSize="0.7rem"
					/>
				</div>
				<div className="preference-wrapper">
					<div className="form-left">Information panel </div>
					<SwitchComponent
						onChange={(value) => setFacilityInfo(value)}
						controlState={facilityInfo}
						values={[
							{ value: show, label: 'Show' },
							{ value: hide, label: 'Hide' },
						]}
						height={23.5}
						width={270}
						marginTop={4}
						fontSize="0.7rem"
					/>
				</div>
				{hasPermission(PERMISSIONS.CAN_ACCESS_DR_BATTERY) && (
					<div className="preference-wrapper">
						<div className="form-left">Log messages panel </div>
						<SwitchComponent
							onChange={(value) => setLog(value)}
							controlState={logs}
							values={[
								{ value: show, label: 'Show' },
								{ value: hide, label: 'Hide' },
							]}
							height={23.5}
							width={270}
							marginTop={4}
							fontSize="0.7rem"
						/>
					</div>
				)}
				<div className="preference-wrapper">
					<div className="form-left">Default timezone</div>
					<SwitchComponent
						onChange={(value) => setFacilityPageTimezone(parseInt(value))}
						controlState={facilityPageTimezone}
						values={[
							{ value: facilityTimezone, label: 'Facility' },
							{ value: fleetTimezone, label: 'Fleet' },
							{ value: localTimezone, label: 'Local' },
							{ value: utcTimezone, label: 'UTC' },
						]}
						height={23.5}
						width={270}
						marginTop={4}
						fontSize="0.7rem"
					/>
				</div>
			</div>
		);
	};

	useEffect(() => {
		if (UserService.hasPreferences()) {
			const preference = UserService.getPreferences();
			
			if (hasPermission(PERMISSIONS.CAN_ACCESS_AF)) {
				setPerformance(preference[config.performance]);
				setBattery(preference[config.battery]);
				setAggFacilityData(preference[config.aggFacilityData]);
			}

			if (hasPermission(PERMISSIONS.CAN_ACCESS_DR_BATTERY)) {
				setStatus(preference[config.status]);
				setLog(preference[config.logsPanel]);
			}

			setFleetPageTimezone(preference[config.fleetPageTimezone]);
			setFacilityPageTimezone(preference[config.facilityPageTimezone]);
			setEnergy(preference[config.energyData]);
			setFacilityInfo(preference[config.facilityInfo]);
			setFacilityData(preference[config.facilityData]);

			setCredits(preference[config.showCreditsManagement]);

			setForecasts(preference[config.showForecasts] || show);

			getInitialPageState(preference[config.initialPage]);
		}
	}, []);

	useEffect(() => {
		if (UserService.hasPreferences()) {
			let disabled = true;
			const preference = UserService.getPreferences();
			if (hasPermission(PERMISSIONS.CAN_ACCESS_AF)) {
				if (
					preference[config.performance] !== performance ||
					preference[config.battery] !== battery ||
					preference[config.aggFacilityData] !== aggFacilityData
				) {
					disabled = false;
				}
			}

			if (hasPermission(PERMISSIONS.CAN_ACCESS_DR_BATTERY)) {
				if (preference[config.status] !== status || preference[config.logsPanel] !== logs) {
					disabled = false;
				}

				if (preference[config.showForecasts] !== forecasts) {
					disabled = false;
				}
			}

			if (
				preference[config.fleetPageTimezone] !== fleetPageTimezone ||
				preference[config.facilityPageTimezone] !== facilityPageTimezone ||
				preference[config.energyData] !== energy ||
				preference[config.facilityInfo] !== facilityInfo ||
				preference[config.facilityData] !== facilityData
			) {
				disabled = false;
			}

			if (
				hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER) &&
				preference[config.showCreditsManagement] !== credits
			) {
				disabled = false;
			}

			if (preference[config.initialPage] !== initialPage) {
				disabled = false;
			}

			setDisabledSettingButton(disabled);
		}
	}, [
		aggFacilityData,
		battery,
		energy,
		facilityData,
		facilityInfo,
		facilityPageTimezone,
		fleetPageTimezone,
		logs,
		performance,
		status,
		credits,
		forecasts,
		initialPage,
	]);

	return (
		<TabContainer title="Dashboard settings">
			<form className="settings-form" autoComplete="off">
				{initialPagePreference()}
				{renderFleetPreferences()}
				{renderFleetCustomerPreferences()}
				{renderFacilityPreferences()}

				<div
					style={{
						height: 1,
						backgroundColor: '#ebebeb',
					}}
				/>
				<div className="settings-btn-wrapper">
					<Button className="configure-btn reset" type="button" onClick={(e) => handleCancelPreferences(e)}>
						Cancel
					</Button>
					<Button
						className="configure-btn submit"
						type="button"
						onClick={(e) => handleSavePreferences(e)}
						disabled={disabledSettingButton}
					>
						Save
					</Button>
				</div>
			</form>
		</TabContainer>
	);
};

export default SettingsFrom;
